* {
  margin: 0px;
  padding: 0px;
}

/* Add this CSS to your main stylesheet or a CSS module specific to the Navbar */

/* src/components/Navbar.css */
.logo-img {
  max-height: 50px; /* Adjust based on your logo size */
}

/* src/components/Navbar.css */
.logo-img {
  max-height: 50px;
}

.navbar-nav .nav-link {
  font-size: 16px;
  margin-left: 15px;
  transition: color 0.3s ease;
}

.navbar-nav .nav-link:hover {
  color: #007bff;
}

.navbar-nav .nav-link {
  font-size: 16px; /* Adjust font size if needed */
  margin-left: 15px;
  transition: color 0.3s ease;
}

.navbar-nav .nav-link:hover {
  color: #007bff; /* Change color on hover */
}

/* Social Icons styling */
.social-icons a {
  color: #333; /* Default color for icons */
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #007bff; /* Color change on hover */
}

/* Add this CSS to your main stylesheet or component-specific CSS module */

.card-img-container {
  position: relative;
  overflow: hidden;
}

.card-img {
  width: 100%;
  height: 200px; /* Adjust height as needed */
  object-fit: cover;
  object-position: center;
}
.custom-modal {
  width: 90%; /* Adjust this to control modal width */
  max-width: 1000px; /* Set max-width to control excessive size */
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 1000px; /* Ensures modal remains large on wider screens */
  }
}

.modal-body {
  max-height: calc(100vh - 150px); /* Adjusts to fit screen height */
  overflow-y: auto; /* Only scroll if content exceeds modal height */
}
